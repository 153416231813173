import React from "react";

export default class Promessa extends React.Component {
  render() {
    return (
      <>
        <div className="wrapper">
          <div style={{ marginTop: "25vh" }} className="main ">
            <h3 className="header slideLeft">Em desenvolvimento</h3>
            <div className="sedeContainer">  </div>

            <div style={{ display: 'none' }} className="sedeResponsivo"> </div>
          </div>
        </div>
      </>
    );
  }
}
